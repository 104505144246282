import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-previous-step',
    template: `
        <a [routerLink]="route" class="u-inline-flex-row u-flex-align-items-center u-link-inverted u-color-content-secondary-default"
            ><img class="u-margin-right-xs" ngSrc="https://assets.smooved.be/icons/arrow-left.svg" width="12" height="12" /><span
                class="u-font-size-sm"
                >{{ labelResource | translate }}</span
            ></a
        >
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TranslateModule, RouterModule, NgOptimizedImage],
})
export class PreviousStepComponent {
    @Input() public route: string;
    @Input() public labelResource: string = 'SERVICES.PREVIOUS_STEP';
}
