app-lineup-offer-button {
    .__container {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        justify-content: center;
    }

    button > span {
        width: 100%;
        height: 100%;
    }
}
